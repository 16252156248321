import STitle from '../../components/ui/s-title'
import SContainer from '../../components/ui/s-container'
import SLink from '../../components/ui/s-link'

import backIcon from '../../assets/images/icone_voltar_simples.svg'

export default {
  name: 'FAQ',
  props: {
    showBackBtn: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SContainer,
    SLink,
    STitle,
  },

  data: () => ({ backIcon }),
}
